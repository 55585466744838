<!-- NEW-ADMIN PARTNER -->
<template>
  <div>
    <q-dialog v-model="showDialog">
      <q-card style="width: 100%">
        <q-bar>
          <span class="form-title">{{ mode }} a Partner</span>
          <q-space />
          <q-btn round flat v-close-popup icon="far fa-times" />
        </q-bar>
        <q-form class="q-pa-md">
          <h6>Partner properties</h6>
          <q-input
            filled
            v-model="partner.name"
            label="Partner name"
            hint=""
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
          />

          <q-input
            filled
            v-model="partner.slug"
            label="Slug"
            hint=""
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
          />

          <q-editor v-model="partner.introduction">{{
            partner.introduction
          }}</q-editor>

          <h6>
            Administrators
            <q-btn
              @click="addAdmin = !addAdmin"
              flat
              size="sm"
              icon="fas fa-plus"
              label="Add"
            >
              <q-dialog v-model="addAdmin">
                <q-card>
                  <q-bar>
                    <span class="form-title">Add admin</span>
                    <q-space />
                    <q-btn round flat v-close-popup icon="far fa-times" />
                  </q-bar>
                  <q-card-section>
                    Type name or email of existing member:
                    <q-select
                      filled
                      v-model="newPartnerMember"
                      :options="filteredMembers"
                      use-input
                      input-debounce="2"
                      option-value="uuid"
                      option-label="label"
                      @filter="filterMember"
                      hint="Existing Historiana members"
                    >
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            No results
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-card-section>
                  <q-card-actions align="center">
                    <q-btn v-close-popup label="Cancel" />
                    <q-btn
                      color="primary"
                      label="Ok"
                      :disable="!(newPartnerMember && newPartnerMember.uuid)"
                      @click="partnerAddMember(partner, newPartnerMember)"
                    />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </q-btn>
          </h6>
          <q-banner
            v-if="partner.admins && partner.admins.length < 1"
            class="bg-red text-white"
          >
            <q-icon
              size="md"
              class="q-mr-md"
              name="fas fa-exclamation-circle"
            ></q-icon>
            <strong>There are no administrators</strong>
          </q-banner>
          <q-list>
            <q-expansion-item
              expand-separator
              icon="perm_identity"
              :label="admin.name"
              :caption="admin.email"
              v-for="(admin, i) of partner.admins"
              :key="i"
            >
              <q-card style="width: 100vw">
                <q-card-section>
                  <small>uuid:</small>{{ admin.uuid }}<br />
                  <small>joined:</small>
                  {{
                    new Date(
                      admin.joined === parseInt(admin.joined)
                        ? admin.joined
                        : admin.joined * 1000
                    ).toLocaleDateString()
                  }}
                </q-card-section>
                <q-card-actions>
                  <q-btn
                    size="small"
                    color="red"
                    label="Revoke"
                    icon="fas fa-times"
                    @click="partnerRevokeMember(partner, admin)"
                  />
                </q-card-actions>
              </q-card>
            </q-expansion-item>
          </q-list>

          <hr />

          <h6>Settings</h6>
          <q-checkbox
            filled
            v-model="partner.is_visible"
            label="Partner is visibile in public site"
            hint=""
            lazy-rules
          />

          <hr />
          <h6>Europeana integration</h6>
          <q-select
            v-model="partner.dataproviders"
            multiple
            :options="filteredDataproviders"
            use-chips
            use-input
            fill-input
            stack-label
            input-debounce="2"
            @filter="filterDataProvider"
            hint="Associated Europeana Dataproviders"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>

          <q-select
            v-model="partner.providers"
            multiple
            :options="filteredProviders"
            use-chips
            use-input
            fill-input
            stack-label
            input-debounce="2"
            @filter="filterProvider"
            hint="Associated Europeana Providers"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>

          <q-card-actions align="center">
            <q-btn label="Cancel" v-close-popup color="secondary" />
            <q-btn label="Save" @click="save" color="primary" />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <section>
      <q-table
        :data="partners"
        :columns="columns"
        row-key="uuid"
        :pagination="{ rowsPerPage: 20 }"
        :dense="false"
        :filter="filter"
        wrap-cells
      >
        <template v-slot:top-left>
          <q-btn color="primary" icon="fa fa-plus" label="Add" @click="add" />
        </template>
        <template v-slot:top-right>
          <q-input
            outlined
            dense
            debounce="300"
            v-model="filter"
            placeholder="Search"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              dense
              round
              flat
              color="grey"
              @click="edit(props)"
              icon="fad fa-pencil"
            ></q-btn>
            <q-btn
              dense
              round
              flat
              color="grey"
              @click="remove(props)"
              icon="fad fa-trash"
            ></q-btn>
          </q-td>
        </template>
      </q-table>
    </section>
  </div>
</template>
<script>
import { API } from "../tic";
import { QSpinnerGears } from "quasar";

export default {
  name: "admin-partner",
  props: ["showAdd"],
  watch: {
    showAdd() {
      this.add();
    },
  },

  data() {
    return {
      addAdmin: false,
      searchMember: "",
      modelMultiple: [],
      mode: null, //edit or add
      showDialog: false,
      roles: [],
      preset: "",
      filter: "",
      showEdit: false,
      archetypes: [],
      actions: [],
      cleanPerms: {},
      perms: {},
      permset: {},
      name: "",
      code: "",
      newPartnerMember: [],
      filteredMembers: [],
      members: [],

      filteredDataproviders: [],
      filteredProviders: [],
      dataproviders: [],
      providers: [],
      partners: [],
      partner: {
        name: "",
        slug: "",
        icon: "",
        logo: "",
        uuid: "",
        introduction: "",
        is_visible: false,
        dataproviders: [],
        providers: [],
        admins: [],
      },
      columns: [
        // {
        //   name: "uuid",
        //   label: "uuid",
        //   field: (row) => row.uuid,
        //   align: "left",
        // },
        {
          name: "name",
          label: "name",
          field: (row) => row.name,
          align: "left",
        },
        {
          name: "slug",
          label: "slug",
          field: (row) => row.slug,
          align: "left",
        },
        {
          name: "icon",
          label: "icon",
          field: (row) => row.icon,
          format: (val) => (val ? "✓" : "-"),
          align: "left",
        },
        {
          name: "logo",
          label: "logo",
          field: (row) => row.logo,
          format: (val) => (val ? "✓" : "-"),
          align: "left",
        },
        {
          name: "visible",
          label: "visible",
          field: (row) => row.is_visible,
          format: (val) => (val ? "✓" : "-"),
          align: "left",
        },
        {
          name: "admins",
          label: "admins",
          //field: (row) => row.admins.map((e) => e.email).join(", "),
          field: (row) => row.admins.length,
          style: (row) => (row.admins.length ? "" : "color: red"),
          //(row.admins ? row.admins.email || "" : ""),
          //format: (val) => val.admins,
          align: "left",
        },
        // { name: 'code', label:'code', field: row => row.code, align:'left' },
        { name: "actions", label: "action", field: "actions", align: "right" },
      ],
    };
  },

  // https://www.raymondcamden.com/2019/08/12/working-with-the-keyboard-in-your-vue-app
  created() {
    window.addEventListener("keydown", this.doCommand);
  },

  destroyed() {
    window.removeEventListener("keydown", this.doCommand);
  },

  mounted() {
    this.load();

    // get Europeana (data)providers
    this.$q.loading.show({ message: "Fetching Europeana providers" });
    API.get("/system/eu-providers").then((r) => {
      this.providers = r.data.data.providers;
      this.dataproviders = r.data.data.dataproviders;
      // we hide in load() for now
      this.$q.loading.hide();
    });
  },

  methods: {
    load() {
      this.$q.loading.hide();

      // get partners
      API.get("/system/partners").then((r) => {
        this.partners = r.data.data;
      });

      API.get("/system/member-select").then((r) => {
        this.members = r.data.data;
      });
    },

    partnerRevokeMember(partner, member) {
      // update local instance of members for popup to sync
      this.partner.admins = this.partner.admins.filter((e) => {
        return e.uuid !== member.uuid;
      });

      // remove from Graph
      API.post("/system/partner-revoke-member", {
        partner: partner.uuid,
        member: member.uuid,
      }).then((r) => {
        this.load();
      });
    },

    partnerAddMember(partner, member) {
      console.log(`add new member ${member.uuid} to ${partner.uuid}`);
      // add this member to the list so its rendered in the popup
      console.log("bla: ", partner.admins);
      this.partner.admins.push(member.member);
      // only save when this is an existing Partner
      // (we have no uuid if this is a new partner)
      if (this.partner.uuid) {
        API.post("/system/partner-add-member", {
          partner: partner.uuid,
          member: member.uuid,
        }).then((r) => {
          this.load();
          this.addAdmin = false;
          this.newPartnerMember = "";
        });
      }
      this.addAdmin = false;
    },

    // autocomplete for Members
    filterMember(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredMembers = this.members.filter((v) => {
          return v.label?.toLowerCase().indexOf(needle) > -1;
        });
      });
    },

    // autocomplete for DataProvider
    filterDataProvider(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredDataproviders = this.dataproviders.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    // autocomplete for Provider
    filterProvider(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredProviders = this.providers.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    add() {
      this.mode = "add";
      this.showDialog = true;
    },

    doCommand(e) {
      if (e.keyCode === 27) {
        this.cancel();
      }
    },

    cancel() {
      this.showEdit = false;
      this.$emit("hide");
    },

    edit(props) {
      this.mode = "edit";
      // this.partner = { ...props.row };
      this.partner = { ...props.row };

      if (
        this.partner.providers === undefined ||
        this.partner.providers === ""
      ) {
        this.partner.providers = [];
      }
      if (
        this.partner.dataproviders === undefined ||
        this.partner.dataproviders === ""
      ) {
        this.partner.dataproviders = [];
      }

      this.showDialog = true;
    },

    remove(props) {
      this.$q
        .dialog({
          title: '<i class="fas fa-trash"></i>&nbsp;Confirm',
          message: `Really delete this item?<br><b>${props.row.name}</b>`,
          cancel: true,
          persistent: true,
          html: true,
        })
        .onOk(() => {
          API.post("/partners/delete", { uuid: props.row.uuid }).then((r) => {
            // reload data after delete
            this.load();
          });
        });
    },

    save() {
      const vm = this;
      console.log("SAVE P : ", this.mode);
      // save the data, then clean local copy
      this.$q.loading.show({
        spinner: QSpinnerGears,
        spinnerColor: "green",
        messageColor: "green",
        backgroundColor: "lightgrey",
      });

      API.post(`/partners/${this.mode}`, this.partner)
        .then((r) => {
          this.$q.loading.hide();
          this.showDialog = false;
        })
        .then((r) => {
          vm.load();
        });
    },

    // get the value for the checkbox, deal with missing entries
    getCheckboxValue(type, perm) {
      try {
        let val = this.perms[type][perm];
      } catch (err) {
        if (this.perms[type] === undefined) {
          this.perms[type] = {};
          this.perms[type][perm] = false;
        }
      }

      // do not return undefined
      return this.perms[type][perm] ? this.perms[type][perm] : false;
    },

    checkboxClick(value, type, perm) {
      this.perms[type][perm] = !this.perms[type][perm];
      // force an update, Vue is not smart enough to detect the mutation above.
      this.$forceUpdate();
    },
  },
};
</script>
<style>
.permissions td:nth-child(even) {
  background-color: #eee;
}

table {
  table-layout: fixed;
}

.q-data-table td,
.q-data-table th {
  /* don't shorten cell contents */

  white-space: normal !important;
  word-wrap: break-word;
}
</style>